$(function () {
    
    var swiper_3 = undefined;
    function initSwiper3() {
        var screenWidth = $(window).width();
        if(screenWidth <= 1100 && swiper_3 == undefined) {            
            swiper_3 = new Swiper('.cuadroBox .swiper-container', {
                slidesPerView: 5,
                loop: false,
                spaceBetween: 16,
                observer:true,
                roundLengths:true,
                slidesPerGroup: 1,
                navigation: {
                    nextEl: '.cuadroBox .swiper-button-next',
                    prevEl: '.cuadroBox .swiper-button-prev',
                },
                breakpoints: {
                    1300: {
                        slidesPerView: 5,
                        slidesPerGroup: 1,
                        spaceBetween: 16,
                    },
                    1100: {
                        slidesPerView: 4,
                        slidesPerGroup: 1,
                        spaceBetween: 14,
                    },
                    1000: {
                        slidesPerView: 3,
                        slidesPerGroup: 1,
                        spaceBetween: 12,
                    },
                    980: {
                        slidesPerView: 2,
                        slidesPerGroup: 1,
                        spaceBetween: 10,
                    },
                    660: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                        spaceBetween: 10,
                    }
                }
            });
        } else if (screenWidth > 1100 && swiper_3 != undefined) {
            swiper_3.destroy();
            swiper_3 = undefined;
            $('.cuadroBox .swiper-wrapper').removeAttr('style');
            $('.cuadroBox .swiper-slide').removeAttr('style');            
        }
    }
    //Swiper plugin initialization
    initSwiper3();
    //Swiper plugin initialization on window resize
    $(window).on('resize', function(){
        initSwiper3();       
    });


});
